import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { HomePage } from "./components/pages/HomePage";
import { useAnalytics } from "./utils/useAnalytics";

export const Routes: React.FC = () => {
  useAnalytics();
  return (
    <Switch>
      <Route exact path="/" component={HomePage} />

      <Redirect to="/" />
    </Switch>
  );
};
