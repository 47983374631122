import { useAccountsData } from "@saberhq/sail";
import {
  calculateVirtualPrice,
  makeExchangeInfo,
  StableSwap,
  SWAP_PROGRAM_ID,
} from "@saberhq/stableswap-sdk";
import { chunk, zip } from "lodash";
import { useMemo } from "react";

import type { ExchangeMap } from "../../contexts/router";
import { useEnvironment } from "../useEnvironment";
import type { ProgramAddressInput } from "../useProgramAddresses";
import {
  ProgramAddressType,
  useProgramAddresses,
} from "../useProgramAddresses";

interface AllPools {
  loading: boolean;
  poolsMap: ExchangeMap;
}

/**
 * Loads all pools in the environment.
 * @returns
 */
export const useAllPools = (): AllPools => {
  const { pools } = useEnvironment();
  const poolsList = useMemo(() => Object.values(pools), [pools]);
  const swapAccountKeys = useMemo(
    () => poolsList.map((pool) => pool.swapAccount),
    [poolsList]
  );
  const swapKeys = useMemo(
    () =>
      swapAccountKeys.map(
        (key): ProgramAddressInput => ({
          type: ProgramAddressType.SWAP_AUTHORITY,
          path: [key],
        })
      ),
    [swapAccountKeys]
  );
  const swapDatas = useAccountsData(swapAccountKeys);
  const swapAuthorities = useProgramAddresses(swapKeys);

  const swaps = useMemo(() => {
    return zip(swapDatas, swapAuthorities).map(([swapData, authority]) => {
      if (!swapData || !authority) {
        return null;
      }
      return StableSwap.loadWithData(
        swapData.accountId,
        swapData.accountInfo.data,
        authority,
        SWAP_PROGRAM_ID
      );
    });
  }, [swapAuthorities, swapDatas]);

  const exchangeAccounts = useMemo(
    () =>
      swaps.flatMap((swap) => [
        swap?.state.tokenA.reserve,
        swap?.state.tokenB.reserve,
        swap?.state.poolTokenMint,
      ]),
    [swaps]
  );
  const exchangeDatas = useAccountsData(exchangeAccounts);

  const poolsMap: ExchangeMap = useMemo(() => {
    const result = zip(swaps, poolsList, chunk(exchangeDatas, 3)).map(
      ([swap, pool, exchangeAccounts]) => {
        if (!exchangeAccounts || !pool || !swap) {
          return null;
        }
        const [tokenAReserve, tokenBReserve, poolTokenMint] = exchangeAccounts;
        if (!tokenAReserve || !tokenBReserve || !poolTokenMint) {
          return null;
        }
        const exchangeInfo = makeExchangeInfo({
          exchange: pool,
          swap,
          accounts: {
            reserveA: tokenAReserve.accountInfo.data,
            reserveB: tokenBReserve.accountInfo.data,
            poolMint: poolTokenMint.accountInfo.data,
          },
        });
        const virtualPrice = calculateVirtualPrice(exchangeInfo);

        return [
          pool.lpToken.mintAccount.toString(),
          {
            exchange: pool,
            swap,
            info: exchangeInfo,
            virtualPrice,
          },
        ] as const;
      }
    );

    return result.reduce((acc, el) => {
      if (!el) {
        return acc;
      }
      return {
        ...acc,
        [el[0]]: el[1],
      };
    }, {});
  }, [exchangeDatas, poolsList, swaps]);

  const loading = useMemo(
    () =>
      exchangeDatas.findIndex((x) => x === undefined) !== -1 ||
      swapDatas.findIndex((x) => x === undefined) !== -1,
    [exchangeDatas, swapDatas]
  );
  return { poolsMap, loading };
};
