import React from "react";
import { BrowserRouter } from "react-router-dom";

import { globalStyles } from "./globalStyles";
import { Routes } from "./routes";

export const App: React.FC = () => {
  return (
    <div className="App">
      {globalStyles}
      <BrowserRouter basename="/">
        <Routes />
      </BrowserRouter>
    </div>
  );
};
