import { SWAP_PROGRAM_ID } from "@saberhq/stableswap-sdk";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  Token as SPLToken,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import type { SunnySDK } from "@sunnyaggregator/sunny-sdk";
import {
  CREATOR_KEY,
  findMinerAddress,
  findPoolAddress,
  findQuarryAddress,
  findRewarderAddress,
  findVaultAddress,
  SBR_ADDRESS,
  SUNNY_ADDRESSES,
} from "@sunnyaggregator/sunny-sdk";
import { useEffect, useState } from "react";

import { useSDK } from "../contexts/sdk";

export enum ProgramAddressType {
  ATA = "ATA",

  PLOT = "PLOT",
  FARMER = "FARMER",
  SWAP_AUTHORITY = "SWAP_AUTHORITY",

  SS_POOL = "SS_POOL",
  SS_VAULT = "SS_VAULT",

  MINE_REWARDER = "MINE_REWARDER",
  MINE_QUARRY = "MINE_QUARRY",
  MINE_MINER = "MINE_MINER",
}

const strategies: {
  [T in ProgramAddressType]: (
    path: ProgramAddressInputPaths[T],
    sunny: SunnySDK
  ) => Promise<PublicKey>;
} = {
  [ProgramAddressType.ATA]: async (path) => {
    return await SPLToken.getAssociatedTokenAddress(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      path[0],
      path[1]
    );
  },
  [ProgramAddressType.PLOT]: async (path, sunny) => {
    return await sunny.programs.Farm.account.plot.associatedAddress(
      path[0],
      path[1]
    );
  },
  [ProgramAddressType.FARMER]: async ([landlord, token, owner], sunny) => {
    const plot = await sunny.programs.Farm.account.plot.associatedAddress(
      landlord,
      token
    );
    return sunny.programs.Farm.account.farmer.associatedAddress(owner, plot);
  },
  [ProgramAddressType.SWAP_AUTHORITY]: async (path) => {
    const [address] = await PublicKey.findProgramAddress(
      [path[0].toBuffer()],
      SWAP_PROGRAM_ID
    );
    return address;
  },

  [ProgramAddressType.SS_POOL]: async ([landlord, token]) => {
    const [address] = await findPoolAddress({
      programId: SUNNY_ADDRESSES.SunnySaberFarm,
      rewardsMint: SBR_ADDRESS,
      creator: CREATOR_KEY,
      landlord,
      lpMint: token,
    });
    return address;
  },
  [ProgramAddressType.SS_VAULT]: async ([landlord, token, owner]) => {
    const [pool] = await findPoolAddress({
      programId: SUNNY_ADDRESSES.SunnySaberFarm,
      rewardsMint: SBR_ADDRESS,
      creator: CREATOR_KEY,
      landlord,
      lpMint: token,
    });
    const [vault] = await findVaultAddress({
      programId: SUNNY_ADDRESSES.SunnySaberFarm,
      pool,
      owner,
    });
    return vault;
  },

  [ProgramAddressType.MINE_REWARDER]: async ([base]) => {
    const [rewarder] = await findRewarderAddress(base);
    return rewarder;
  },
  [ProgramAddressType.MINE_QUARRY]: async ([rewarder, token]) => {
    const [quarry] = await findQuarryAddress(rewarder, token);
    return quarry;
  },
  [ProgramAddressType.MINE_MINER]: async ([rewarder, token, owner]) => {
    const [quarry] = await findQuarryAddress(rewarder, token);
    const [miner] = await findMinerAddress(quarry, owner);
    return miner;
  },
};

const associationCache: Record<string, PublicKey> = {};

export type ProgramAddressInputPaths = {
  [ProgramAddressType.ATA]: readonly [token: PublicKey, owner: PublicKey];
  [ProgramAddressType.PLOT]: readonly [landlord: PublicKey, token: PublicKey];
  [ProgramAddressType.FARMER]: readonly [
    landlord: PublicKey,
    token: PublicKey,
    owner: PublicKey
  ];
  [ProgramAddressType.SWAP_AUTHORITY]: readonly [swapAccount: PublicKey];

  [ProgramAddressType.SS_POOL]: readonly [
    landlord: PublicKey,
    token: PublicKey
  ];
  [ProgramAddressType.SS_VAULT]: readonly [
    landlord: PublicKey,
    token: PublicKey,
    owner: PublicKey
  ];

  [ProgramAddressType.MINE_REWARDER]: readonly [base: PublicKey];
  [ProgramAddressType.MINE_QUARRY]: readonly [
    rewarder: PublicKey,
    tokenMint: PublicKey
  ];
  [ProgramAddressType.MINE_MINER]: readonly [
    rewarder: PublicKey,
    tokenMint: PublicKey,
    owner: PublicKey
  ];
};

export type ProgramAddressInput<
  K extends ProgramAddressType = ProgramAddressType
> = {
  type: K;
  path: ProgramAddressInputPaths[K];
};

const makeCacheKey = ({ type, path }: ProgramAddressInput): string =>
  `${type}.${path.map((p) => p.toBuffer().toString("base64")).join(".")}`;

/**
 * Loads and caches program addresses.
 * @param addresses
 * @returns
 */
export const useProgramAddresses = (
  addresses: (ProgramAddressInput | null)[]
): (PublicKey | null)[] => {
  const { sunny } = useSDK();
  const [keys, setKeys] = useState<(PublicKey | null)[]>(
    addresses.map((addr) => {
      if (!addr) {
        return null;
      }
      const cacheKey = makeCacheKey(addr);
      if (associationCache[cacheKey]) {
        return associationCache[cacheKey] ?? null;
      }
      return null;
    })
  );

  useEffect(() => {
    void (async () => {
      setKeys(
        await Promise.all(
          addresses.map(
            async <K extends ProgramAddressType>(
              addr: ProgramAddressInput<K> | null
            ): Promise<PublicKey | null> => {
              if (!addr) {
                return null;
              }
              const cacheKey = makeCacheKey(addr);
              if (associationCache[cacheKey]) {
                return associationCache[cacheKey] ?? null;
              }
              const strategy = strategies[addr.type] as (
                path: ProgramAddressInputPaths[K],
                sunny: SunnySDK
              ) => Promise<PublicKey>;
              const nextKey = await strategy(addr.path, sunny);
              associationCache[cacheKey] = nextKey;
              return nextKey;
            }
          )
        )
      );
    })();
  }, [addresses, sunny]);

  return keys;
};
