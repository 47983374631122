import { Fraction } from "@saberhq/token-utils";
import * as Sentry from "@sentry/react";
import { useMemo } from "react";
import useSWR from "swr";

const TOKENS = [
  "bitcoin",
  "ftx-token",
  "saber",
  "serum",
  "terra-luna",
  "solana",
] as const;
type CoingeckoToken = typeof TOKENS[number];

const COINGECKO_PRICES = `https://api.coingecko.com/api/v3/simple/price?ids=${TOKENS.join(
  "%2C"
)}&vs_currencies=usd`;

type PriceInfo = {
  price: Fraction | null;
  loading: boolean;
};

export interface CoingeckoPrices {
  btc: PriceInfo;
  luna: PriceInfo;
  sol: PriceInfo;
  fttPriceUSD: Fraction | null;
  fttPriceLoading: boolean;
  sbrPriceUSD: Fraction | null;
  sbrPriceLoading: boolean;
  serumPriceUSD: Fraction | null;
  serumPriceLoading: boolean;
}

/**
 *
 * @returns Use the Coingecko prices.
 */
export const useCoingeckoPrices = (): CoingeckoPrices => {
  const { data: coingeckoPriceDataRaw, error } = useSWR<
    {
      [C in CoingeckoToken]: {
        usd: number;
      };
    },
    Error
  >(COINGECKO_PRICES);
  if (error) {
    console.warn("Error fetching Coingecko prices", error);
    Sentry.captureException(error);
  }

  return useMemo(() => {
    if (!coingeckoPriceDataRaw) {
      const loading = {
        price: null,
        loading: true,
      };
      return {
        btc: loading,
        luna: loading,
        sol: loading,
        fttPriceLoading: true,
        fttPriceUSD: null,
        sbrPriceLoading: true,
        sbrPriceUSD: null,
        serumPriceLoading: true,
        serumPriceUSD: null,
      };
    }
    const fttPriceRaw = coingeckoPriceDataRaw?.["ftx-token"].usd;
    const fttPriceUSD = Fraction.fromNumber(fttPriceRaw);

    const sbrPriceRaw = coingeckoPriceDataRaw?.saber.usd;
    const sbrPriceUSD = Fraction.fromNumber(sbrPriceRaw);

    const serumPriceRaw = coingeckoPriceDataRaw?.serum.usd;
    const serumPriceUSD = Fraction.fromNumber(serumPriceRaw);

    return {
      btc: {
        price: Fraction.fromNumber(coingeckoPriceDataRaw["bitcoin"].usd),
        loading: false,
      },
      luna: {
        price: Fraction.fromNumber(coingeckoPriceDataRaw["terra-luna"].usd),
        loading: false,
      },
      fttPriceUSD,
      fttPriceLoading: false,
      sbrPriceUSD,
      sbrPriceLoading: false,
      serumPriceUSD,
      serumPriceLoading: false,
      sol: {
        price: Fraction.fromNumber(coingeckoPriceDataRaw["solana"].usd),
        loading: false,
      },
    };
  }, [coingeckoPriceDataRaw]);
};
