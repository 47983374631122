import { decodeEventQueue, Market } from "@project-serum/serum";
import { useAccountData } from "@saberhq/sail";
import { Fraction } from "@saberhq/token-utils";
import type { PublicKey } from "@solana/web3.js";
import { SUNNY_INFO } from "@sunnyaggregator/sunny-sdk";
import { useMemo } from "react";

import { SERUM_DEX_PROGRAM_ID } from "../../utils/constants";

export const useSerumPrice = (
  market: PublicKey
): {
  loading: boolean;
  price: Fraction | null;
} => {
  const { data: sunnyPriceData, loading: sunnyMarketLoading } =
    useAccountData(market);
  const { sunnyUSDCSerumMarket, eventQueueKey } = useMemo(() => {
    if (!sunnyPriceData?.accountInfo.data) {
      return { eventQueueKey: null, sunnyUSDCSerumMarket: null };
    }
    const decoded: unknown = (
      Market.getLayout(SERUM_DEX_PROGRAM_ID) as {
        decode: (data: Buffer) => unknown;
      }
    ).decode(sunnyPriceData.accountInfo.data);
    const eventQueueKey = (decoded as { eventQueue: PublicKey }).eventQueue;
    const market = new Market(
      decoded,
      SUNNY_INFO.decimals,
      // USDC decimals
      6,
      {},
      SERUM_DEX_PROGRAM_ID
    );
    return { eventQueueKey, sunnyUSDCSerumMarket: market };
  }, [sunnyPriceData]);

  const { data: sunnyEventQueueData, loading: eventQueueLoading } =
    useAccountData(eventQueueKey);

  const sunnyPriceUSD = useMemo(() => {
    if (!sunnyEventQueueData || !sunnyUSDCSerumMarket) {
      return null;
    }
    const events = decodeEventQueue(sunnyEventQueueData.accountInfo.data, 100);
    const allEvents = events
      .filter(
        (event) => event.eventFlags.fill && event.nativeQuantityPaid.gtn(0)
      )
      .map(sunnyUSDCSerumMarket.parseFillEvent.bind(sunnyUSDCSerumMarket));
    const lastPrice = (allEvents[0] as { price: number } | undefined)?.price;
    if (typeof lastPrice !== "number") {
      return null;
    }
    return Fraction.fromNumber(lastPrice);
  }, [sunnyUSDCSerumMarket, sunnyEventQueueData]);

  return {
    loading: sunnyMarketLoading || eventQueueLoading,
    price: sunnyPriceUSD,
  };
};
