import { css, Global } from "@emotion/react";
import React from "react";

import { breakpoints } from "./theme/breakpoints";

export const globalStyles = (
  <Global
    styles={(theme) => css`
      html {
        box-sizing: border-box;
      }

      [data-reach-dialog-overlay] {
        z-index: 10000;
      }

      *,
      *:before,
      *:after {
        box-sizing: inherit;
      }

      body {
        overflow-y: scroll;
      }

      body {
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
          "Segoe UI Symbol";

        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background: ${theme.colors.base.primary};
        color: ${theme.colors.text.default};
      }

      #root,
      html {
        /* background: #fafafb; */
        background: #000;
      }
      .App {
        overflow: hidden;
      }

      code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
          monospace;
      }

      .ant-notification {
        ${breakpoints.mobile} {
          margin-bottom: 56px;
        }
      }
      .ant-notification-notice {
        border-radius: 8px;
        box-shadow: 0 1px 16px rgba(255, 255, 255, 0.2);
      }
      a:focus {
        text-decoration: underline;
      }
    `}
  />
);
