import type { Token } from "@saberhq/token-utils";
import invariant from "tiny-invariant";

import type { CurrencyMarket } from "./currencies";

export class TokenGroups {
  private readonly groups: {
    [g in CurrencyMarket]?: {
      [address: string]: Token;
    };
  } = {};
  private readonly groupsByAsset: Record<string, CurrencyMarket> = {};

  add(tag: CurrencyMarket, asset: Token): void {
    const group = this.groups[tag];
    if (!group) {
      this.groups[tag] = { [asset.address]: asset };
    } else {
      group[asset.address] = asset;
    }
    invariant(
      !this.groupsByAsset[asset.address],
      "asset is already in a group"
    );
    this.groupsByAsset[asset.address] = tag;
  }

  getGroup(asset: Token): CurrencyMarket | undefined {
    return this.groupsByAsset[asset.address];
  }

  getTradeableAssets(asset: Token): Token[] | null {
    const grp = this.getGroup(asset);
    if (!grp) {
      return null;
    }
    const values = this.groups[grp];
    return values ? Object.values(values) : [];
  }

  canTradeWith(asset: Token, other: Token): boolean {
    const myGrp = this.getGroup(asset);
    const otherGrp = this.getGroup(other);
    return myGrp === otherGrp;
  }
}
