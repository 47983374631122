import styled from "@emotion/styled";

import { formatDisplayWithSoftLimit } from "../../utils/format";

interface Props {
  value?: number | null;
  numDigits?: number;
  initialWholeDigits?: number;
}
function getLocalizedDecimalSeparator() {
  const numberWithDecimalSeparator = 1.1;

  return numberWithDecimalSeparator.toLocaleString().substring(1, 2);
}

export const Digits: React.FC<Props> = ({ value }: Props) => {
  if (value === null || value === undefined) {
    return (
      <BlankDigitHolder>
        <BlankDigit> </BlankDigit>
        <BlankDigit> </BlankDigit>
        <BlankDigit> </BlankDigit>
        <BlankNonNumberChar>
          {getLocalizedDecimalSeparator()}
        </BlankNonNumberChar>
        <BlankDigit> </BlankDigit>
        <BlankDigit> </BlankDigit>
        <BlankDigit> </BlankDigit>
        <BlankDigit> </BlankDigit>
      </BlankDigitHolder>
    );
  }

  const stringNumber = formatDisplayWithSoftLimit(
    value,
    6,
    6 + 1, // BE VERY CAREFUL CHANGING THIS
    {
      useGrouping: false,
    },
    "en-US"
  );

  const arrayNumber = stringNumber.split("");
  return (
    <ForceMonospace>
      {arrayNumber.map((digit, i) => {
        if (Number.isInteger(Number(digit))) {
          return <NumberDigit key={i}>{digit}</NumberDigit>;
        }
        return (
          <BlankNonNumberChar key={i}>
            <NonNumberChar>{digit}</NonNumberChar>
          </BlankNonNumberChar>
        );
      })}
    </ForceMonospace>
  );
};

const BlankDigitHolder = styled.span`
  display: flex;
  gap: 3px;
  font-weight: bold;
  color: #bbb;
`;

const BlankDigit = styled.span`
  background: #ddd;
  color: #333;
  border-radius: 4px;
  width: 13px;
  margin-top: 1px;
  margin-bottom: 1px;
`;

// variable width digit glyphs...
const ForceMonospace = styled.span`
  display: flex;
`;

// Uncomment the font to watch it spaz out
const NumberDigit = styled.span`
  // font-family: Times New Roman;
  min-width: 16px;
  text-align: center;
  color: #444;
`;

const NonNumberChar = styled.span`
  color: #444;
  width: 6px;
  text-align: center;
`;

const BlankNonNumberChar = styled.span`
  width: 6px;
  text-align: center;
`;
