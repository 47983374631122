export const themeJSON = {
  "bg gradient": "linear-gradient(0deg, #e29034 36%, #e29034 100%)",
  dark: {
    overlay: "rgba(23,23,23,0.8)",
    button: {
      primary: {
        buttontext: { muted: "#777", default: "#333" },
        base: {
          default: "hsl(45, 100%, 58%)",
          hover: "hsl(45, 100%, 65%)",
          pressed: "hsl(45, 100%, 48%)",
          disabled: "#dadadd",
        },
      },
      secondary: {
        base: {
          default: "hsl(45, 100%, 60%)",
          hover: "hsl(45, 100%, 65%)",
          pressed: "hsl(45, 100%, 50%)",
          disabled: "#dadadd",
        },
        buttontext: { muted: "#777", default: "#333" },
      },
    },
    text: {
      muted: "#464a4f",
      default: "#223",
      bold: "#000",
      accent: "#55bbf7",
      green: "#4ebe96",
      orange: "#f99d69",
      red: "#d84f68",
    },
    base: { primary: "#fff", secondary: "#eee", tertiary: "#ddd" },
    divider: { primary: "#313131", secondary: "#313131" },
    iconselector: {
      icon: { default: "#b0b0b0" },
      base: { default: "#26272b", hover: "#212228" },
    },
    cryptoselector: { base: { default: "#eaeaeb", hover: "#e8e8e9" } },
    tabs: { base: { active: "#131419" } },
    brand: { logo: "#e29034" },
    gradients: {
      phantom: "linear-gradient(0deg, #5347b7 0%, #551ff3 100%)",
      sollet: "linear-gradient(0deg, #067ad7 0%, #2196f3 100%)",
      ledger: "linear-gradient(0deg, #616774 0%, #403f4c 100%)",
    },
    modal: { item: { base: { hover: "#e1e1e1" } }, base: { default: "#eee" } },
  },
  gradients: {
    mathwallet: "linear-gradient(0deg, #8c8f97 0%, #565657 100%)",
    solletextension: "linear-gradient(0deg, #2196f3 0%, #067ad7 100%)",
  },
  buttontext: {
    fontWeight: 500,
    fontSize: "18px",
    textDecoration: "none",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0%",
    textIndent: "0px",
  },
  smallbuttontext: {
    fontWeight: 600,
    fontSize: "16px",
    textDecoration: "none",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0%",
    textIndent: "0px",
  },
  "swap box shadow": "0px 4px 8px rgba(0,0,0,0.25)",
  modalshadow: "0px 16px 70px rgba(0,0,0,0.5)",
} as const;
