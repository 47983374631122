import { useConnectionContext } from "@saberhq/use-solana";
import * as Sentry from "@sentry/react";
import type { TokenList } from "@solana/spl-token-registry";

import tokenListDevnet from "./data/token-list.devnet.json";
import tokenListMainnet from "./data/token-list.mainnet.json";

export const useTokenList = (): {
  loading: boolean;
  data: TokenList | null | undefined;
} => {
  const { network } = useConnectionContext();
  const data =
    network === "mainnet-beta"
      ? tokenListMainnet
      : network === "devnet"
      ? tokenListDevnet
      : null;
  if (data === null) {
    Sentry.captureException(new Error(`Invalid network ${network}`));
  }

  return {
    loading: false,
    data,
  };
};
