import type { Theme } from "@emotion/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { darken } from "polished";

export type IButtonVariant = keyof Theme["colors"]["button"] | "danger";

const red1 = "#FD4040";

const danger = {
  base: {
    default: "linear-gradient(0deg, #616774 0%, #403f4c 100%)",
    disabled: `linear-gradient(180deg, ${darken(0.4, red1)} 0%, ${darken(
      0.5,
      red1
    )} 100%)`,
    hover: "linear-gradient(0deg, #767b87 0%, #5e5d6f 100%)",
    pressed: "linear-gradient(0deg, #4c515b 0%, #35353e 100%)",
  },
  buttontext: { default: "#fff", disabled: "#fff", muted: "#fff" },
};

export const Button = styled.button<{
  variant?: IButtonVariant;
  size?: "small" | "large";
}>`
  ${({ theme, variant = "primary" }) => {
    const buttonColors =
      variant === "danger"
        ? danger
        : (theme.colors.button[variant] as {
            readonly buttontext: {
              readonly muted?: string;
              readonly disabled?: string;
              readonly default: string;
            };
            readonly base: {
              readonly default: string;
              readonly hover: string;
              readonly pressed: string;
              readonly disabled: string;
            };
          });
    return css`
      color: ${buttonColors.buttontext.default};
      background: ${buttonColors.base.default};
      &:hover {
        background: ${buttonColors.base.hover};
      }
      &:disabled {
        color: ${buttonColors.buttontext.disabled ??
        buttonColors.buttontext.muted};
        background: ${buttonColors.base.disabled};
      }
      &:active:not(:disabled) {
        background: ${buttonColors.base.pressed};
      }
    `;
  }}

  ${({ theme, size = "small" }) =>
    size === "large"
      ? css`
          height: 64px;
          width: 100%;
          border-radius: 4px;
          ${theme.buttontext};
        `
      : css`
          height: 43px;
          padding: 0 12px;
          border-radius: 8px;
          ${theme.smallbuttontext};
        `};

  display: flex;
  align-items: center;
  justify-content: center;
  transition: font-size 0.3s ease;

  box-shadow: none;
  border: none;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
`;
