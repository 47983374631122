import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useWalletKit } from "@gokiprotocol/walletkit";
import { SOL, TokenAmount } from "@saberhq/token-utils";
import { useConnectedWallet, useConnectionContext } from "@saberhq/use-solana";
import { darken } from "polished";
import React, { useState } from "react";
import { FaCaretDown } from "react-icons/fa";

import { useConfig } from "../../../../contexts/config";
import { useSDK } from "../../../../contexts/sdk";
import { breakpoints } from "../../../../theme/breakpoints";
import { shortenAddress } from "../../../../utils/utils";
import { Button } from "../../../common/Button";
import { TokenAmountDisplay } from "../../../common/TokenAmountDisplay";
import { WalletSettingsModal } from "./WalletSettingsModal";

export const ConnectedWallet: React.FC = () => {
  const wallet = useConnectedWallet();
  const { connect } = useWalletKit();

  const { nativeBalance } = useSDK();
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const { network } = useConnectionContext();
  const { environments } = useConfig();
  const networkName = environments[network].name;
  const [mode, setMode] = useState<"change-network" | "settings">("settings");

  const inner = (
    <>
      {network !== "mainnet-beta" && (
        <NetworkName
          onClick={() => {
            setMode("change-network");
            setShowSettings(true);
          }}
        >
          {networkName}
        </NetworkName>
      )}
      {wallet ? (
        <Wrapper onClick={() => setShowSettings(true)}>
          <Info>
            <AccountIcon />
            <InfoText>
              <Balance>
                {nativeBalance === undefined ? (
                  "-- SOL"
                ) : (
                  <TokenAmountDisplay
                    amount={nativeBalance ?? new TokenAmount(SOL[network], 0)}
                    numberFormatOptions={{
                      maximumFractionDigits: 4,
                    }}
                  />
                )}
              </Balance>
              <TextAddress>
                {shortenAddress(`${wallet.publicKey.toString()}`)}
              </TextAddress>
            </InfoText>
          </Info>
          <DownIcon />
        </Wrapper>
      ) : (
        <Button
          size="small"
          variant="secondary"
          onClick={connect}
          css={css`
            min-width: 180px;

            ${breakpoints.mobile} {
              min-width: 0;
            }
          `}
        >
          Connect Wallet
        </Button>
      )}
    </>
  );

  return (
    <>
      <WalletSettingsModal
        mode={mode}
        setMode={setMode}
        isOpen={showSettings}
        onDismiss={() => setShowSettings(false)}
      />
      {inner}
    </>
  );
};

const NetworkName = styled.div`
  padding: 8px 12px;
  border-radius: 8px;
  line-height: 1em;
  font-weight: 600;
  // background: ${darken(0.4, "#ffc929")};
  color: #ffc929;
  margin-right: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background: ${darken(0.3, "#ffc929")};
  }

  ${breakpoints.mobile} {
    padding: 8px 0;
    font-size: 13px;
  }
`;

const DownIcon = styled(FaCaretDown)`
  fill: ${({ theme }) => theme.colors.text.muted};
`;

const Balance = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16.71px;
  color: ${({ theme }) => theme.colors.text.bold};
`;

const TextAddress = styled.div`
  font-size: 12px;
  line-height: 14.32px;
  color: ${({ theme }) => theme.colors.text.default};
`;

const InfoText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

const Info = styled.div`
  display: grid;
  grid-template-columns: 32px 1fr;
  grid-column-gap: 8px;
`;

const AccountIcon = styled.div`
  background: linear-gradient(180deg, #d329fc 0%, #8f6dde 49.48%, #19e6ad 100%);
  width: 32px;
  height: 32px;
  border-radius: 100%;
`;

const Wrapper = styled.button`
  border-radius: 16px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 180px;
  border: none;
  appearance: none;
  text-align: left;
  ${breakpoints.mobile} {
    width: 100%;
  }
  padding: 8px;

  ${({ theme }) => theme["swap box shadow"]};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.base.tertiary};
  }
  transition: 0.3s ease;
`;
