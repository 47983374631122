import styled from "@emotion/styled";
import type { DetailedHTMLProps, ImgHTMLAttributes } from "react";

const SBR_LOGO =
  "https://cdn.jsdelivr.net/gh/saber-hq/spl-token-icons@master/icons/101/Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1.svg";

type Props = DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

export const TokenIcon: React.FC<Props> = ({ ...innerProps }: Props) => {
  if (innerProps.src === "https://app.saber.so/tokens/slp.png") {
    return <ImageWrapper {...innerProps} src={SBR_LOGO} />;
  }
  return <ImageWrapper {...innerProps} />;
};

const ImageWrapper = styled.img`
  height: 20px;
  vertical-align: middle;
  border-radius: 50%;
`;
