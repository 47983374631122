export const BREAKPOINT_SIZES = [400, 576, 780, 992, 1200] as const;

const maxMediaQueries = BREAKPOINT_SIZES.map(
  (bp) => `@media (max-width: ${bp}px)`
);

export const breakpoints = {
  tinymobile: maxMediaQueries[0],
  mobile: maxMediaQueries[1],
  tablet: maxMediaQueries[2],
  medium: maxMediaQueries[3],
};
