import styled from "@emotion/styled";
import { ErrorBoundary } from "@sentry/react";
import type { PropsWithChildren } from "react-router/node_modules/@types/react";

type Props = PropsWithChildren<unknown>;

export const SunnyErrorBoundary: React.FC<Props> = ({ children }: Props) => (
  <ErrorBoundary
    fallback={
      <ErrorMessage>
        An unexpected error occurred while loading this component.
      </ErrorMessage>
    }
  >
    {children}
  </ErrorBoundary>
);

const ErrorMessage = styled.p`
  color: red;
`;
