import styled from "@emotion/styled";

import { ReactComponent as GradientSunLogoOne } from "./svgs/GradientSunLogoOne.svg";
import { ReactComponent as GradientSunLogoTwo } from "./svgs/GradientSunLogoTwo.svg";

// https://github.com/irodger/svg-id-randomizer
// When loadingspinner disappears, it rugs the styles from SlowRotatingSunLogo

export const LoadingSpinner = styled(GradientSunLogoOne)`
  animation-name: spin;
  animation-duration: 2500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  }
`;

export const SlowRotatingSunLogo = styled(GradientSunLogoTwo)`
  animation-name: spin;
  animation-duration: 27275ms; // https://en.wikipedia.org/wiki/Solar_rotation#Carrington_rotation
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  }
`;
