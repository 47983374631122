import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { IFormatUint, Percent, TokenAmount } from "@saberhq/token-utils";
import React from "react";

import { formatPercent } from "../../utils/format";
import { TokenIcon } from "./TokenIcon";

export interface IProps extends IFormatUint {
  amount: TokenAmount;
  isMonoNumber?: boolean;
  showIcon?: boolean;
  percent?: Percent;
  className?: string;
  showSymbol?: boolean;
}

export const TokenAmountDisplay: React.FC<IProps> = ({
  amount,
  isMonoNumber = false,
  showIcon = false,
  showSymbol = true,
  percent,
  className,
  ...uintFormat
}: IProps) => {
  return (
    <TokenAmountWrapper className={className}>
      {showIcon && (
        <TokenIcon
          css={css`
            margin-right: 4px;
          `}
          src={amount.token.icon}
          alt={`Icon of token ${amount.token.symbol}`}
        />
      )}
      <TheNumber isMonoNumber={isMonoNumber}>
        {amount.format({
          numberFormatOptions: {
            maximumFractionDigits: amount.token.decimals,
          },
          ...uintFormat,
        })}
      </TheNumber>

      {showSymbol && (
        <span>
          {"\u00A0"}
          {amount.token.symbol}
        </span>
      )}
      {percent && <PercentFmt>({formatPercent(percent)})</PercentFmt>}
    </TokenAmountWrapper>
  );
};

const PercentFmt = styled.span`
  margin-left: 4px;
`;

const TokenAmountWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TheNumber = styled.span<{ isMonoNumber?: boolean }>`
  ${({ theme, isMonoNumber }) =>
    isMonoNumber === true
      ? css`
          ${theme.mono}
        `
      : undefined}
`;
