import type { Token } from "@saberhq/token-utils";
import { Percent, TokenAmount } from "@saberhq/token-utils";
import type { u64 } from "@solana/spl-token";
import type { Farmer, Landlord, Plot } from "@sunnyaggregator/sunny-sdk";
import { Payroll } from "@sunnyaggregator/sunny-sdk";
import BN from "bn.js";

export interface PlotStats {
  rewardsShare: Percent | null;
  totalDeposits: TokenAmount | null;
  payroll: Payroll | null;
  stakedAmount: TokenAmount | null;
  rewardsPerDay: TokenAmount | null;
  // userRewardsPerDay: TokenAmount | null;
}

export const calculatePlotStats = ({
  landlord,
  plot,
  // farmer,
  token,
  sbr,
  farmerBalance,
}: {
  landlord?: Landlord | null;
  plot?: Plot | null;
  farmer?: Farmer | null;
  token?: Token;
  sbr?: Token | null;
  farmerBalance?: u64;
}): PlotStats => {
  const rewardsShare =
    plot && landlord
      ? new Percent(plot.rewardsShare, landlord.totalRewardsShares)
      : null;

  const totalDeposits =
    plot && token ? new TokenAmount(token, plot.totalTokensDeposited) : null;

  const payroll =
    plot && totalDeposits
      ? new Payroll(
          plot.famineTs,
          plot.lastUpdateTs,
          plot.dailyRewardsRate.div(new BN(86_400)),
          plot.rewardsPerTokenStored,
          new BN(plot.tokenMintDecimals),
          totalDeposits.toU64()
        )
      : null;

  const stakedAmount =
    farmerBalance && token ? new TokenAmount(token, farmerBalance) : null;

  const rewardsPerDay =
    plot && sbr ? new TokenAmount(sbr, plot.dailyRewardsRate) : null;

  // Shows saber yields, not sunny yields
  // const userRewardsPerDay =
  //   plot && farmer && sbr && totalDeposits?.greaterThan(0)
  //     ? new TokenAmount(
  //         sbr,
  //         plot.dailyRewardsRate
  //           .mul(stakedAmount?.toU64() ?? new BN(0))
  //           .div(new BN(totalDeposits.raw.toString()))
  //       )
  //     : null;

  return {
    rewardsShare,
    totalDeposits,
    payroll,
    stakedAmount,
    rewardsPerDay,
    // userRewardsPerDay,
  };
};
